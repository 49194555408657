export default {
  el: {
    "entrance": "πύλη",
    "see": "δείτε",
    "more": "περισσότερα",
    "less": "λιγότερα",
    "please.wait": "Παρακαλώ περιμένετε",
    "not.found": "Δεν βρέθηκε",
  },
  en: {
    "entrance": "entrance",
    "see": "see",
    "more": "more",
    "less": "less",
    "please.wait": "Please wait",
    "not.found": "Not found",
  },
};
