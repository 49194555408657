// import Cookies from "js-cookie";

// export const CookiesApi = Cookies.withConverter({
//   read: function (value, name) {
//     return decodeURIComponent(value);
//   },
//   write: function (value, name) {
//     return encodeURIComponent(value);
//   },
// });

// HELPFUL FUNCTIONS FOR GLOBAL USE
export const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
];

export const MAX_SIZE = 2097152; //2MB max file upload size

export const base64EncodeUnicode = (str) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  let utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (
    match,
    p1
  ) {
    return String.fromCharCode("0x" + p1);
  });

  return btoa(utf8Bytes);
};

export const removeAccents = (value) =>
  value
    .toLowerCase()
    .replace(/ά/g, "α")
    .replace(/έ/g, "ε")
    .replace(/ύ/g, "υ")
    .replace(/ί/g, "ι")
    .replace(/ϊ/g, "ι")
    .replace(/ΐ/g, "ι")
    .replace(/ή/g, "η")
    .replace(/ό/g, "ο")
    .replace(/ϋ/g, "υ")
    .replace(/ΰ/g, "υ")
    .replace(/ς/g, "σ")
    .replace(/ώ/g, "ω");

export const sizeOf = (bytes) => {
  if (bytes == 0) {
    return "0.00 B";
  }
  let e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B"
  );
};

export const checkFiles = (files_array, errors_array) => {
  // console.log("FILES ERRORS", file_errors);
  let file_errors = [];
  console.log("FILES ERRORS", file_errors);
  //check uploaded files (the ones deemed obligatory) for presence and format and size
  //be sure to grab the "file" attribute of each object in the array
  files_array.forEach((file) => {
    // console.log(file);
    if (file.file === "" || file.file === null) {
      if (file.obligatory) {
        let str = "Παρακαλώ επιλέξτε αρχείο";
        file_errors.push({ path: file.fileTitle, message: str });
      }
    } else {
      let doublicates = files_array.filter(
        (f) =>
          f.file &&
          f.fileTitle &&
          f.file[0].name == file.file[0].name &&
          f.file[0].size == file.file[0].size
      );

      if (doublicates.length > 1) {
        let str =
          "Το ίδιο αρχείο δεν επιτρέπεται να υποβληθεί σε διαφορετικά δικαιολογητικά";
        file_errors.push({ path: file.fileTitle, message: str });
      } else if (file.file[0].size > MAX_SIZE) {
        let str = "Το μέγεθος του αρχείου δεν επιτρέπεται να ξεπερνάει τα 2MB";
        file_errors.push({ path: file.fileTitle, message: str });
      } else if (!SUPPORTED_FORMATS.includes(file.file[0].type)) {
        let str = "Επιτρεπόμενοι τύποι αρχείου: doc, docx, pdf, jpeg, png";
        file_errors.push({ path: file.fileTitle, message: str });
      }
    }
  });
  // console.log("Errors from files: "+ JSON.stringify(file_errors) );
  console.log("FILES ERRORS", file_errors);
  return file_errors;
};

export const findErrors = (validation_errors, field_path) => {
  // console.log(field_path);
  // console.log(validation_errors);
  let filter = validation_errors.filter((error) => error.path === field_path);
  // console.log(filter);
  return filter;
};

//access values of nested object without knowing name
export const finder = (obj) => {
  // for each property in the object passed into
  // the function...
  for (var p in obj) {
    // if its value is another object
    if (Object.prototype.toString.call(obj[p]).slice(8, -1) === "Object") {
      // ...log the key to the console and then call finder()
      // again with this current object (this is the recursive part)
      console.log("key: " + p + ", value: Object");
      finder(obj[p]);

      // otherwise log the key/value
    } else {
      console.log("key: " + p + ", value: ", obj[p]);
      return obj[p];
    }
  }
};

export const sortTableRows = (rows, filter_name, type) => {};

export const alphabetically = (a, b) => {
  const alphabet = "αάβγδεέζηήθιίκλμνξοόπρστυύφχψωώ";

  a = a.toLowerCase().trim().split(" ")[0];
  b = b.toLowerCase().trim().split(" ")[0];
  // Find the first position were the strings do not match
  let position = 0;
  while (a[position] === b[position]) {
    // If both are the same don't swap
    if (!a[position] && !b[position]) return 0;
    // Otherwise the shorter one goes first
    if (!a[position]) return 1;
    if (!b[position]) return -1;
    position++;
  }
  // Then sort by the characters position
  return alphabet.indexOf(a[position]) - alphabet.indexOf(b[position]);
};

export const formatMimeType = (mimeType) => {
  console.log("mimeType", mimeType);

  if (mimeType) {
    let parts = mimeType.split("/");
    let tmp = mimeType;
    if (parts.length > 1) {
      tmp = parts[1];
    }

    if (tmp) {
      tmp = tmp.toUpperCase();
      console.log("mimeType is", tmp);
      switch (tmp) {
        case "PLAIN":
          tmp = "TXT";
          break;
        case "VND.OASIS.OPENDOCUMENT.SPREADSHEET":
          tmp = "ODS";
          break;
        case "VND.OASIS.OPENDOCUMENT.TEXT":
          tmp = "ODT";
          break;
        case "X-DBF":
          tmp = "DBF";
          break;
        case "VND.MS-EXCEL":
          tmp = "XLS";
          break;
        case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET":
          tmp = "XLSX";
          break;
        case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT":
          tmp = "DOCX";
          break;
        case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION":
          tmp = "PPTX";
          break;
        case "VND.MS-POWERPOINT":
          tmp = "PPT";
          break;
        case "X-TIKA-OOXML":
          tmp = "DOCX";
          break;
        case "MSWORD":
          tmp = "DOC";
          break;
        case "X-TIKA-MSOFFICE":
          tmp = "DOC";
          break;
        case "SVG+XML":
          tmp = "SVG";
          break;
        default:
          if (tmp.length > 4) {
            tmp = "...";
          }
          break;
      }
    }
    return tmp;
  } else return "";
};

//CODE SNIPPET FROM jsvat library: https://github.com/se-panfilov/jsvat
export const greekVATMultipliers = [256, 128, 64, 32, 16, 8, 4, 2];

export const checkIfGreekVATisValid = (vatnumber) => {
  let total = 0;

  // eight character numbers should be prefixed with an 0.
  let newVat = vatnumber.length === 8 ? "0" + vatnumber : vatnumber;

  // Extract the next digit and multiply by the counter.
  for (let i = 0; i < 8; i++) {
    total += Number(newVat.charAt(i)) * greekVATMultipliers[i];
  }

  // Establish check digit.
  total = total % 11;
  total = total > 9 ? 0 : total;

  // Compare it with the last character of the VAT number. If it's the same, then it's valid.
  const expect = Number(newVat.slice(8, 9));
  return total.toString() === expect.toString();
};
