<svelte:options tag="entrance-services" />

<script>
  // Important notes:
  // 1. use node 17
  // 2. styles of every component need to be declared here
  // questions @tthoma
  import ChildComponent from "./ChildComponent.svelte";

  import { Modal, openModal, closeModal } from "renderless-svelte";
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  } from "@rgossiaux/svelte-headlessui";
  import { fade } from "svelte/transition";
  import {
    AlertCircleIcon,
    DownloadIcon,
    PrinterIcon,
    SearchIcon,
  } from "svelte-feather-icons";
  import {
    formatMimeType,
    removeAccents,
    alphabetically,
  } from "../src/helpers.js";
  import printJS from "print-js";

  import { t, localeStore } from "./i18n";
  import { element, toggle_class } from "svelte/internal";

  export let locale = "el";
  export let dark = false;
  export let client = "";
  export let theme = "blue";
  export let entranceurl = "";
  export let gsisauthurl = "";
  export let userid;

  let loggedin;
  $: if (userid == "unregistered" || userid == undefined) {
    loggedin = false;
  } else {
    loggedin = true;
  }

  $: $localeStore = locale;
  $: darkMode = dark == "true";
  $: console.log("COMP - org to fetch: ", entranceurl);
  $: console.log("COMP - GSIS auth url: ", gsisauthurl);

  let allOrgData = [];
  let services = [];
  let categories = [];
  let waiting = false;

  let categoriesContainingServices = []; //The categories that after a search contain the service(s) that match the query
  let filesRequested = []; //Δικαιολογητικά

  let accordionIsOpen = []; //Boolean that manipulates the accordion's functionality
  let modalIsHidden = true; //Boolean that manipulates the modal's visibility
  let chosenService = null; //The service that a user chose by clicking on it

  let searchQuery = ""; //A variable that stores the search query given by the user
  let filteredServices = []; //The service(s) that match the search query

  let searchHidden = false;

  let inputSearchElement;

  const getServices = function () {
    waiting = true;

    fetch(entranceurl + "/registeredOrganization/" + client)
      .then(function (result) {
        console.log("COMP - fetch result: ", result);
        return result.json();
      })
      .then(function (data) {
        let tempServices = data.data.servicesProvided;

        tempServices = tempServices.filter((s) => {
          return !s.serviceName.startsWith("!");
        });

        services = tempServices.sort((a, b) =>
          alphabetically(a.serviceName, b.serviceName)
        );

        filteredServices = services;
        allOrgData = data.data;

        // 1. Split services accoording to their respective "category", keep categories in a Set (unique values)
        categories = [...new Set(services.map((el) => el.serviceCategory))];
        categoriesContainingServices = categories;

        categories.forEach((element) => {
          accordionIsOpen.push(false);
        });

        if (categories.length == 1 && services.length <= 6) {
          searchHidden = true;
        }

        // 2. Return services in an object, one for each category, which is {category name, services array}
        // the reducer function creates a new array for each different "serviceCategory" attribute.
        // Each Array is then pushed to a parent Array ( [] ), which is returned as result
        // services = data.data.servicesProvided.reduce((r, o) => {
        //   Object.entries(o).forEach(([key, val]) => {
        //     if (key === "serviceCategory") (r[val] = r[val] || []).push(o);
        //   });
        //   return r.sort();
        // }, []);

        //no errors, move to next and final step
        // selectedOrgDTO = data.data;
        // $clientTerms = selectedOrgDTO.termsMessage;
        // $clientPrivacy = selectedOrgDTO.gdprMessage;
        // $clientFooterName = selectedOrgDTO.title;
        // $currentClientGsisId = selectedOrgDTO.gsisClientId;
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      })
      .finally(function () {
        waiting = false;
      });
  };

  //api call to get registered organization data
  $: if (client && client != "" && entranceurl != "") {
    getServices();
  }

  // Function to close the modal box and its background
  function close() {
    closeModal();
    modalIsHidden = true;
    chosenService = null;
  }

  //--------------------------------- PRINT ---------------------------------
  let printable;
  let docTitle = "document";

  function printRawHTML() {
    // let re = new RegExp('^<*>$')
    // let tempPrintable = printable.innerHTML.split(re);
    // console.log(tempPrintable);

    // temp.forEach((element) => {
    //   console.log(element);
    //   let el = element.toString();

    //   if (el.indexOf("id='exclude-print'")) {
    //     element.remove();
    //   }
    // });

    // tempPrintable.childNodes = temp;

    printJS({
      type: "html",
      printable: printable,
      scanStyles: false,
      style:
        "@page {margin: 2cm} {font-family: Calibri} li em {font-size: 12px; padding-left:5px;} li span {font-weight: bold} ul {padding-left: 10px;} ul p {padding-left: 20px; margin-bottom: 20px;}",
      ignoreElements: ["exclude-print"],
      documentTitle: docTitle,
    });
  }

  //--------------------------------- SEARCH ---------------------------------
  function find() {
    categoriesContainingServices = [];
    filteredServices = [];
    var simplifiedSearchQuery = removeAccents(searchQuery.trim()); //Remove extra spaces, accents and special letters
    var splitSearchQuery = simplifiedSearchQuery.split(" "); //Separate the search query word by word

    accordionIsOpen.forEach((element, i) => {
      accordionIsOpen[i] = true;
    });

    services.forEach((service) => {
      var simplifiedServiceName = removeAccents(service.serviceName);
      var simplifiedDescription = removeAccents(service.serviceDescription);

      //Checks if the simplifiedServiceName has all splitSearchQuery's words; if so returns true
      var containsAllInTitle = splitSearchQuery.every((queryWord) => {
        return simplifiedServiceName.includes(queryWord);
      });

      var containsAllInDescription = splitSearchQuery.every((queryWord) => {
        return simplifiedDescription.includes(queryWord);
      });

      if (containsAllInTitle || containsAllInDescription) {
        filteredServices.push(service);

        if (!categoriesContainingServices.includes(service.serviceCategory)) {
          categoriesContainingServices.push(service.serviceCategory);
        }
      }
    });
  }

  let calledFind = false;
  $: if (searchQuery) {
    calledFind = true;
    find();
  }

  $: if (calledFind && searchQuery === "") {
    closeSearch();
  }

  function closeSearch() {
    calledFind = false;
    filteredServices = services;
    categoriesContainingServices = categories;

    accordionIsOpen.forEach((element, i) => {
      accordionIsOpen[i] = false;
    });
  }

  //--------------------------------- DOWNLOAD ---------------------------------
  let headersArray = [];
  let filesReady = false;
  let response;
  const createDownloadLink = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        fetch(file.templateFileURI.concat("/content"))
          .then((tmpResponse) => {
            response = tmpResponse;
            return tmpResponse.blob();
          })
          .then((blob) => {
            // Inspect the headers in the response
            for (let entry of response.headers.entries()) {
              if (entry[0] == "content-type") headersArray.push(entry[1]);
            }

            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              reject();
            } else {
              console.log(response);
              // create the download URL, set the type of file it's sending
              let mimeType = formatMimeType(headersArray[0]);
              file["templateURI"] = URL.createObjectURL(blob);

              //if mimetype is of certain type, add property to file (we need it for a download bug)
              if (
                mimeType === "DOC" ||
                mimeType === "DOCX" ||
                mimeType === "ODT" ||
                mimeType === "PDF"
              )
                file["type"] = mimeType.toLowerCase();

              resolve(file);
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  let promises = [];
  const updateFilesURL = () => {
    modalIsHidden = true; //reset flag
    if (chosenService.filesRequested.length > 0) {
      promises = [];
      for (let i = 0; i < chosenService.filesRequested.length; i++) {
        if (chosenService.filesRequested[i].templateFileURI) {
          promises.push(createDownloadLink(chosenService.filesRequested[i]));
          console.log("resolving file " + i);
        }
      }

      Promise.all(promises)
        .then((results) => {
          console.log("Resolved all files " + JSON.stringify(results));
          filesRequested = chosenService.filesRequested;

          //all files resolved, you're ready to open the quickView modal
          modalIsHidden = false;
          filesReady = true;
        })
        .catch((e) => {
          // handle errors here
          console.log("Error :-S", e);
        });
    } else {
      //no files to resolve, you're ready to open the quickView modal
      modalIsHidden = false;
      filesReady = true;
    }
  };

  //--------------------------------- TAXIS ---------------------------------
  const prepareGsisLoginUrl = (org, state) => {
    //idea 1: pass organization as an argument in order to get redirectUrl and id/secret
    //idea 2: keep organization data in a cookie
    //let org = $cookies.getObject('organization');

    let requrl = gsisauthurl;
    let reqscope = "read";
    let reqresponsetype = "code";
    let reqapproval = "auto";
    let reqredirect = org.gsisRedirectUrl;
    let reqclientid = org.gsisClientId;
    let reqState = state;

    return (
      requrl +
      "?scope=" +
      reqscope +
      "&response_type=" +
      reqresponsetype +
      "&approval_prompt=" +
      reqapproval +
      "&redirect_uri=" +
      reqredirect +
      "&client_id=" +
      reqclientid +
      "&state=" +
      reqState
    );
  };

  const prepareRedirectUrl = (userid, state) => {
    console.log("COMP - inside redirecturl method");
    let url;

    let currentUrl = window.location.href.split("/").slice(0, 3);
    currentUrl = currentUrl.filter((element) => {
      return element !== "";
    });

    let clientUrl = currentUrl[0] + "//" + currentUrl[1];
    let base_url = "/client";
    let org_id = state.split("_")[0];
    let process_id = state.split("_")[1];
    console.log(
      "COMP - redirecturl",
      clientUrl +
        base_url +
        "/" +
        org_id +
        "/action/" +
        process_id +
        "/user/" +
        userid +
        "/new"
    );
    if (process_id === "0") {
      url = clientUrl + base_url + "/" + org_id + "/welcome";
    } else {
      url =
        clientUrl +
        base_url +
        "/" +
        org_id +
        "/action/" +
        process_id +
        "/user/" +
        userid +
        "/new";
    }

    return url;
  };
</script>

<!-- Listener for when the Esc button is pressed; closes the modal -->
<svelte:window on:keyup={(ev) => ev.key === "Escape" && close()} />

<div
  class:red-theme={theme == "red"}
  class:green-theme={theme == "green"}
  class:blue-theme={theme == "blue"}
>
  <div class="disc-border" class:dark={darkMode}>
    <!-- <h1>{$t('entrance')}</h1> -->

    {#if waiting}
      <div class="flex justify-center items-center h-full">
        <div class="spinner-ball m-1 animate-bounce" />
        <div class="spinner-ball m-1 animate-bounce animate-delay-100" />
        <div class="spinner-ball m-1 animate-bounce animate-delay-200" />
      </div>
    {:else if services && services.length}
      <!-- The first page the user sees -->
      <div class="p-15px">
        <!-- Search box -->
        <div
          class="text-center inline-block w-full flex"
          class:hidden={searchHidden}
        >
          {#if searchQuery == ""}
            <button class="btn-search flex items-center">
              <SearchIcon size="24" />
            </button>
          {:else}
            <button
              class="btn-search z-3 px-8px text-2xl leading-tight rounded-full border-0 hover:bg-gray-300 cursor-pointer flex items-center h-40px"
              title="Εκκαθάριση Αναζήτησης"
              on:click={() => {
                searchQuery = "";
                closeSearch();
                inputSearchElement.focus();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          {/if}

          <input
            bind:value={searchQuery}
            bind:this={inputSearchElement}
            class="input-search w-full"
            name="search-box"
            style="--theme: {theme}"
          />
        </div>

        <!-- Checks if the organization has more than one categories; if so an accordion stracture is implemented,
          otherwise displays only the title of the single category (without the accordion) -->

        {#if categories.length > 1}
          {#if categoriesContainingServices != ""}
            <div class="mt-10px">
              <!-- Iterate through categories -->
              {#each categoriesContainingServices as category, i}
                <Disclosure>
                  <DisclosureButton
                    class="generic-btn w-full hover:bg-gray-100 border-0 cursor-pointer"
                    style="padding: 15px;"
                    on:click={() => {
                      accordionIsOpen[i] = !accordionIsOpen[i];
                    }}
                    on:keydown={(e) => {
                      if (e.keyCode && (e.keyCode == 13 || e.keyCode == 32)) {
                        accordionIsOpen[i] = !accordionIsOpen[i];
                      }
                    }}
                  >
                    <div class="flex justify-between text-xl <sm:text-sm">
                      {category}
                      <svg
                        style={accordionIsOpen[i]
                          ? "transform: rotate(0.75turn);"
                          : "transform: rotate(0.25turn)"}
                        width="24"
                        height="24"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke={theme}
                      >
                        <path d="M9 5l7 7-7 7" />
                      </svg>
                    </div>
                  </DisclosureButton>

                  {#if i != accordionIsOpen.length - 1 || (i == accordionIsOpen.length - 1 && accordionIsOpen[i])}
                    <hr class="custom-hr w-full" />
                  {/if}

                  <!-- Iterate through services -->
                  {#each filteredServices as service}
                    {#if service.serviceCategory == category}
                      {#if accordionIsOpen[i]}
                        <DisclosurePanel static>
                          <button
                            on:click={() => {
                              chosenService = service;
                              updateFilesURL();
                              openModal(service.serviceDescription);
                              modalIsHidden = false;
                              filesRequested = service.filesRequested;
                              docTitle = service.serviceName;
                              console.log(
                                "COMP - files requested: ",
                                filesRequested
                              );
                            }}
                            class="light-btn w-full"
                          >
                            {service.serviceName}
                          </button>
                        </DisclosurePanel>
                      {/if}
                    {/if}
                  {/each}

                  {#if i != accordionIsOpen.length - 1}
                    <hr
                      class="custom-hr w-full"
                      class:hidden={!accordionIsOpen[i]}
                    />
                  {/if}
                </Disclosure>
              {/each}
            </div>
          {:else}
            <em class="m-0 mt-15px pl-17px block"
              >Η αναζήτησή σας δεν επέστρεψε αποτελέσματα!</em
            >
          {/if}
        {:else if categories.length <= 1}
          {#if categoriesContainingServices != ""}
            {#each categoriesContainingServices as category}
              <div class="text-xl ml-15px py-10px font-bold mt-10px">
                {category}
              </div>
              <hr class="custom-hr" />
              {#each filteredServices as service}
                {#if service.serviceCategory == category}
                  <button
                    on:click={() => {
                      chosenService = service;
                      updateFilesURL();
                      openModal(service.serviceDescription);
                      modalIsHidden = false;
                      filesRequested = service.filesRequested;
                      docTitle = service.serviceName;
                      console.log("COMP - files requested: ", filesRequested);
                    }}
                    class="light-btn w-full"
                  >
                    {service.serviceName}
                  </button>
                {/if}
              {/each}
            {/each}
          {:else}
            <em class="m-0 mt-15px pl-17px block"
              >Η αναζήτησή σας δεν επέστρεψε αποτελέσματα!</em
            >
          {/if}
        {/if}
      </div>

      <!-- The background behind the modal -->
      <div
        id="background"
        class="h-screen fixed opacity-80 z-1 bg-black inset-0 z-999"
        class:hidden={modalIsHidden}
        on:click={close}
      />

      <!-- The modal containing the details of the clicked service -->
      <div
        id="modal-box"
        class="flex flex-col xl:(w-2/5 h-6/7 rounded-lg) lg:(w-4/6 h-6/7 rounded-lg) m-auto bg-white fixed z-2 inset-0 text-left z-1000"
        class:hidden={modalIsHidden}
      >
        <Modal let:payload>
          {#if chosenService}
            <div class="wrapper">
              <header />

              <!-- The x button of the modal -->
              <button
                class="absolute z-3 -top-20px -right-19.5px px-8px text-2xl leading-tight rounded-full border-0 <md:hidden hover:bg-gray-300
                cursor-pointer flex items-center h-40px <lg:(hidden)"
                class:hidden={modalIsHidden}
                on:click={close}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>

              <div
                id="toprint"
                class="overflow-y-auto px-4"
                bind:this={printable}
              >
                <h2 class="font-bold text-3xl pb-15px m-0 mt-20px">
                  {chosenService.serviceName}
                </h2>
                <span class="text-black text-xl m-0">
                  {@html payload}
                </span>
                <hr class="border-0 bg-gray-300 h-1px" />

                {#if filesRequested != 0}
                  <h3 class="font-bold text-2xl py-15px m-0">Επισυναπτόμενα αρχεία</h3>
                {/if}

                <!-- Iterate through Δικαιολογητικά -->
                {#each filesRequested as file, i}
                  <!-- Checks if the given file is obligatory or not and assigns the correspondent description next to the file's title -->

                  <ul class="pl-0 m-0 my-5px text-md">
                    {#if file.obligatory === true}
                      <li class="text-black m-0 list-none list-outside py-10px">
                        <span
                          class="inline-flex text-2xl font-bold py-5px mr-15px"
                          >{file.fileTitle}</span
                        >
                        <em
                          class="bg-red-100 mr-15px px-10px rounded text-red-800 font-bold py-5px"
                          >Υποχρεωτικό</em
                        >

                        {#if file.templateURI != null && filesReady}
                          <a
                            class="inline-flex top-5px relative no-underline py-5px"
                            id="exclude-print"
                            href={file.templateURI}
                            download={file.type
                              ? file.fileTitle + "." + file.type
                              : file.fileTitle}
                            target="_blank"
                          >
                            <DownloadIcon size="22" />
                            &nbsp;(Πρότυπο έγγραφο)
                          </a>
                        {:else if file.templateFileURI != null && !filesReady}
                          <div
                            class="inline-flex top-5px relative no-underline py-5px"
                          >
                            <DownloadIcon size="22" />
                            &nbsp;(Πρότυπο έγγραφο)
                          </div>
                        {/if}
                      </li>
                    {:else}
                      <li class="text-black m-0 list-none list-outside py-10px">
                        <span
                          class="inline-flex text-2xl font-bold py-5px mr-15px"
                          >{file.fileTitle}</span
                        >
                        <em
                          class="bg-green-100 mr-15px px-10px rounded text-green-800 font-bold py-5px"
                          >Προαιρετικό</em
                        >
                        {#if file.templateURI != null && filesReady}
                          <a
                            class="inline-flex top-5px relative no-underline py-5px"
                            id="exclude-print"
                            href={file.templateURI}
                            download={file.type
                              ? file.fileTitle + "." + file.type
                              : file.fileTitle}
                            target="_blank"
                          >
                            <DownloadIcon size="22" />
                            &nbsp;(Πρότυπο έγγραφο)
                          </a>
                        {:else if file.templateFileURI != null && !filesReady}
                          <div
                            class="inline-flex top-5px relative no-underline py-5px"
                          >
                            <DownloadIcon size="22" />
                            &nbsp;(Πρότυπο έγγραφο)
                          </div>
                        {/if}
                      </li>
                    {/if}

                    {#if file.fileDescription.trim() != file.fileTitle.trim()}
                      <p class="text-xl my-5px">
                        {@html file.fileDescription}
                      </p>
                    {/if}
                  </ul>

                  <hr
                    id="exclude-print"
                    class="my-20px border-0 bg-gray-300 h-1px"
                    class:hidden={i == filesRequested.length - 1}
                  />
                {/each}
              </div>
              <!-- The footer of the modal; contains operating buttons to connect into the service, close the modal
      or print the service's description -->
              <footer class="py-4 w-full">
                <div class="flex justify-between basis-full">
                  <div
                    class:red-theme={theme == "red"}
                    class:green-theme={theme == "green"}
                    class:blue-theme={theme == "blue"}
                    class="flex flex-row"
                  >
                  <!-- don't let user start the service if no fields or files are requested -->
                  {#if !((chosenService.filesRequested.length === 0) && (chosenService.inputFields.length === 0))}
                    <button
                      class="generic-btn action-btn mx-5px mt-5px ml-10px cursor-pointer"
                      on:click={() => {
                        if (loggedin === false) {
                          if (
                            chosenService.authenticationRequired ==
                              "TAXID_VERIFICATION" ||
                            chosenService.authenticationRequired ==
                              "TAXID_OTP_VERIFICATION"
                          ) {
                            window.location.href = prepareGsisLoginUrl(
                              allOrgData,
                              allOrgData.id
                                .toString()
                                .concat("_")
                                .concat(chosenService.id)
                            );
                          } else {
                            window.location.href = prepareRedirectUrl(
                              userid,
                              allOrgData.id
                                .toString()
                                .concat("_")
                                .concat(chosenService.id)
                            );
                          }
                        } else {
                          window.location.href = prepareRedirectUrl(
                            userid,
                            allOrgData.id
                              .toString()
                              .concat("_")
                              .concat(chosenService.id)
                          );
                        }
                      }}
                    >
                      {#if loggedin === false}
                        {#if chosenService.authenticationRequired == "TAXID_VERIFICATION" || chosenService.authenticationRequired == "TAXID_OTP_VERIFICATION"}
                          <span class="<md:hidden"
                            >Σύνδεση με κωδικούς TaxisNet</span
                          >
                          <span class="md:hidden">Σύνδεση με TaxisNet</span>
                        {:else}Νέα Αίτηση{/if}
                      {:else}Νέα Αίτηση{/if}
                    </button>
                    <button
                      class="generic-btn mx-5px mt-5px hover:bg-gray-100 cursor-pointer"
                      on:click={close}>Άκυρο</button
                    >
                  {/if}
                  </div>

                  <!-- disabled={filesRequested == 0} title={filesRequested == 0 ? 'Χωρίς δικαιολογητικά για εκτύπωση!' : null} style={filesRequested == 0 ? 'cursor: not-allowed' : 'cursor: pointer'} -->
                  <button
                    class="generic-btn mx-5px mt-5px mr-10px <md:hidden hover:bg-gray-100 cursor-pointer"
                    on:click={printRawHTML}
                  >
                    <div class="flex flex-row items-center">
                      <PrinterIcon size="24" />
                      <p class="pl-5px m-0">Εκτύπωση</p>
                    </div>
                  </button>

                  <button
                    class="generic-btn md:hidden mx-5px mt-5px mr-10px hover:bg-gray-100 cursor-pointer"
                    on:click={printRawHTML}
                  >
                    <div class="flex flex-row items-center">
                      <PrinterIcon size="24" />
                    </div>
                  </button>
                </div>
              </footer>
            </div>
          {:else}
            <div class="flex justify-center items-center h-full">
              <div class="spinner-ball m-1 animate-bounce" />
              <div class="spinner-ball m-1 animate-bounce animate-delay-100" />
              <div class="spinner-ball m-1 animate-bounce animate-delay-200" />
            </div>
          {/if}
        </Modal>
      </div>
    {:else}
      <div class="flex justify-center items-center">{$t("not.found")}</div>
    {/if}
  </div>
</div>

<style windi:preflights:global windi:safelist:global>
  .red-theme {
    .light-btn {
      color: red;
      @apply bg-transparent hover:bg-red-50;
    }

    .action-btn {
      @apply bg-red-600 border-red-600 text-white hover:bg-red-800;
    }

    .custom-hr {
      @apply bg-red-600 bg-opacity-20;
    }

    .spinner-ball {
      @apply bg-red-300 dark:bg-red-300 rounded-full;
    }

    .disc-border {
      @apply border-red-600 border-opacity-20;
    }
  }

  .green-theme {
    .light-btn {
      color: green;
      @apply bg-transparent hover:bg-green-50;
    }

    .action-btn {
      @apply bg-green-600 border-green-600 text-white hover:bg-green-800 hover:border-green-800;
    }

    .custom-hr {
      @apply bg-green-600 bg-opacity-20;
    }

    .spinner-ball {
      @apply bg-green-300 dark:bg-red-300 rounded-full;
    }

    .disc-border {
      @apply border-green-600 border-opacity-20;
    }
  }

  .blue-theme {
    .light-btn {
      color: blue;
      @apply bg-transparent hover:bg-blue-50;
    }

    .action-btn {
      @apply bg-blue-600 border-blue-600 text-white hover:bg-blue-800 hover:border-green-800;
    }

    .custom-hr {
      @apply bg-blue-600 bg-opacity-20;
    }

    .spinner-ball {
      @apply bg-blue-300 dark:bg-red-300 rounded-full;
    }

    .disc-border {
      @apply border-blue-600 border-opacity-20;
    }
  }

  .custom-hr {
    @apply border-none h-1px m-0;
  }

  .disc-border {
    border: solid;
    border-width: 1px;
    border-radius: 0.25rem;
    padding-bottom: 5px;
  }

  .light-btn {
    @apply py-4 px-5 m-3px rounded-full border-0 cursor-pointer text-left text-lg leading-none;
  }

  .generic-btn {
    @apply py-2 px-4 rounded-full text-lg bg-white border-solid border-gray-100;
  }

  .action-btn {
    @apply border-1 cursor-pointer text-lg border-solid;
  }

  .spinner-ball {
    height: 20px;
    width: 20px;
  }

  .input-search {
    height: 50px;
    border-style: solid;
    border-width: 1px;
    opacity: 70%;
    border-radius: 0.25rem;
    text-align: left;
    text-indent: 50px;
    font-size: large;
    background-color: transparent;
    position: relative;
    /* transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2); */
  }

  .input-search:focus {
    outline: none;
    border: 1px solid var(--theme);
    box-shadow: 0 0 10px #719ece;
    text-align: left;
    text-indent: 50px;
  }

  .input-search::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  .btn-search {
    width: 35px;
    height: 35px;
    margin: 9px 0 0 5px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    color: black;
    background-color: transparent;
    pointer-events: painted;
  }

  .wrapper {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  /* for line length readability */
  span,
  em,
  p {
    @apply inline-block !max-w-34em;
  }
</style>
