import { derived, writable } from "svelte/store";
import translations from "./translations";

export const localeStore = writable("el");

function translate(localeStore, key, vars) {
  // Let's throw some errors if we're trying to use keys/localeStores that don't exist.
  // We could improve this by using Typescript and/or fallback values.
  if (!key) throw new Error("no key provided to $t()");
  if (!localeStore) throw new Error(`no translation for key "${key}"`);

  // Grab the translation from the translations object.
  let text = translations[localeStore][key];

  if (!text) throw new Error(`no translation found for ${localeStore}.${key}`);

  // Replace any passed in variables in the translation string.
  Object.keys(vars).map((k) => {
    const regex = new RegExp(`{{${k}}}`, "g");
    text = text.replace(regex, vars[k]);
  });

  return text;
}

export const t = derived(localeStore, ($localeStore) => (key, vars = {}) =>
  translate($localeStore, key, vars)
);
